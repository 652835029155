<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-Teppostulacion-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Postulaciones
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="fecha_ini" class="col-md-5"
                      >Fecha Inicial</label
                    >
                    <input
                      type="date"
                      id="fecha_ini"
                      v-model="filtros.fecha_ini"
                      class="form-control form-control-sm col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="fecha_fin" class="col-md-5">Fecha Fin</label>
                    <input
                      type="date"
                      id="fecha_fin"
                      v-model="filtros.fecha_fin"
                      class="form-control form-control-sm col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="empresa_id" class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresas"
                      label="razon_social"
                      class="p-0 col-md-7"
                      :options="listasForms.empresas"
                      :filterable="true"
                      @input="getSelectEmpresas()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="campo_id" class="col-md-5">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio"
                      placeholder="sitio"
                      label="nombre"
                      class="p-0 col-md-7"
                      :options="listasForms.sitios"
                      :filterable="true"
                      @input="getSelectCampos()"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExce()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "PostulacionExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      empresa: {},
      sitio: {},
      //SE CREAN LOS CHECKLIST PARA EL BACK
      //SE CREAN FILTROS PARA EL BACK
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
        empresa_id: null,
        campo_id: null,
      },
      //OBTIENE LAS LISTAS DE LOS CORRESPONDIENTES A LAS EMPRESAS
      listasForms: {
        empresas: [],
        sitios: [],
        tipo_ruta: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
    },
  },
  methods: {
    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    async getSitios() {
      await axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },
    getSelectEmpresas() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },
    getSelectCampos() {
      this.filtros.campo_id = null;
      if (this.sitio) {
        this.filtros.campo_id = this.sitio.id;
      }
    },

    //MANDA A LLAMAR LA FUNCION EXPORT EN EL BACK
    generarListadoExce() {
      //this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/postulaciones/export",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getSitios();
    await this.getEmpresas();
    this.cargando = false;
  },
};
</script>
